<template>
    <div class="badge" :id="name">
        <img :src=img width=500 />
        <h2>{{ name }}</h2>
    </div>
</template>

<script>
export default {
    name: 'Badge-Item',
    props: {
        Name: String,
        Image: String
    }
}
</script>

<style scoped>

</style>