<template>
  <div id="app">
    <div class="badge-container">
      <div v-for="badge in markers" :key="badge.name">
        <Badge :name="badge.name" :image="badge.image" />
      </div>
    </div>
    <Map v-if="markers.length > 0" :markers="markers" />
  </div>
</template>

<script>
import Badge from './components/Badge-Item.vue'
import Map from './components/Map-Item.vue'

export default {
  name: 'App',
  data() {
    return {
      markers: []
    }
  },
  components: {
    Badge,
    Map
  },
  mounted() {
    fetch('https://headless.local/wp-json/markers/v1/post')
      .then((r) => r.json())
      .then((res) => this.markers = res.map(x => x.acf));
  }
}
</script>