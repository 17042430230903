<template>
    <div>
        <div class="map" id="map"></div>
    </div>
</template>

<script>
export default {
    name: 'Map-Item',
    mounted() {
        window.mapboxgl.accessToken = "{pk.eyJ1IjoiamFrb2JwbGVzcyIsImEiOiJjbDh5OTRpanYwNnRuM25xaWFpZGphY3hwIn0.ArSGpmOfMXbBt5EeCIBuDQ}";

        var map = new window.mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/dark-v9',
            center: [-81.4608, 48.4758],
            zoom: 4
        });

        map.on('load', (() => {
            this.markers.forEach(function(marker) {
                var el = document.createElement('div');
                el.className = 'marker';

                new window.mapboxgl.Marker(el)
                    .setLngLat([parseFloat(marker.longitude), parseFloat(marker.latitude)])
                    .addTo(map);
            });

            this.markers.forEach((x) => {
                document.getElementById(x.name)
                    .addEventListener('click', () => {
                        map.flyTo({
                            center: [parseFloat(x.latitude), parseFloat(x.longitude)],
                            zoom: 9
                        });
                    })
            })
        }).bind(this));
    },
    props: {
        markers: Array
    }
}
</script>

<style>
.marker {
    background-image: url('./../assets/mapbox-icon.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}
</style>